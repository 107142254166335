import React from "react"
import Layout from "../components/layout/layout"
import ContactForm from "../components/contactform/contactform"
import SEO from "../components/seo"

export default function Contact() {
  return (
    <Layout>
      <SEO
        title="Contact Us"
        description="Contact us today to discuss how we can help your business succeed."
      />
      <ContactForm />
    </Layout>
  )
}
