import React, { useState } from "react"
import clsx from "clsx"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Button from "../button"
import "./contactform.scss"

export default function ContactForm() {
  const [isError, setIsError] = useState(false)
  const [message, setMessage] = useState("")

  async function handleSubmit(e) {
    e.preventDefault()
    const form = e.target
    const formData = new FormData(form)
    try {
      const url = "https://formspree.io/contact@groovegmedia.com"
      const resp = await fetch(url, {
        method: "POST",
        body: formData,
        headers: { Accept: "application/json" },
      })
      if (resp.status !== 200) throw new Error("Sorry, please try again")
      form.reset()
      setMessage("Email sent. We'll be in contact soon.")
    } catch (err) {
      setIsError(true)
      setMessage("Sorry, please try again.")
    }
  }

  return (
    <div className="contact__content">
      <div className="contact__heading">
        <h1>Contact Us Today</h1>
        <p>We look forward to hearing from you</p>
        <a href="tel:+66020641842">
          <FontAwesomeIcon icon="phone-square" />
          +66 02 064 1842
        </a>
      </div>
      <form className="contact__form" onSubmit={handleSubmit}>
        {/* hidden input for formspree */}
        <input type="text" name="_gotcha" style={{ display: "none" }} />
        <label htmlFor="name">
          Name
          <input type="text" name="name" required />
        </label>
        <label htmlFor="_replyTo">
          Email
          <input type="email" name="_replyto" required />
        </label>
        <label htmlFor="message">
          Message
          <textarea name="message" rows="8" required />
        </label>
        {!!message && (
          <p className={clsx("contact__message", isError && "error")}>
            {message}
          </p>
        )}
        <Button type="submit" text="Submit" className="contact__button" />
      </form>
    </div>
  )
}
