import React from "react"
import clsx from "clsx"
import "./button.scss"

const Button = ({ text, className, ...props }) => (
  <button
    type="button"
    className={clsx("styled-button", className)}
    tabIndex="0"
    {...props}
  >
    {text}
  </button>
)

export default Button
